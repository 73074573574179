<template functionnal>
  <div class="template">
    <div class="template__content">
      <h1 class="mb-8">Notre équipe</h1>

      <div class="members-type mt-1">
        <h6>Les principaux membres du conseil d'administration</h6>
        <div class="row members-type__photos">
          <div class="col-3 tile m-0 level pb-6">
            <div class="tile__icon">
              <figure class="avatar avatar--large" data-text="F"></figure>
            </div>
            <div class="tile__container">
              <p class="tile__title m-0">Félicité</p>
              <p class="tile__subtitle m-0">Présidente</p>
            </div>
          </div>

          <div class="col-3 tile m-0 level pb-6">
            <div class="tile__icon">
              <figure class="avatar avatar--large" data-text="C"></figure>
            </div>
            <div class="tile__container">
              <p class="tile__title m-0">Claire</p>
            </div>
          </div>

          <div class="col-3 tile m-0 level pb-6">
            <div class="tile__icon">
              <figure class="avatar avatar--large" data-text="E"></figure>
            </div>
            <div class="tile__container">
              <p class="tile__title m-0">Elisabeth A</p>
              <p class="tile__subtitle m-0">Membre du CA et formatrice FLE</p>
            </div>
          </div>

          <div class="col-3 tile m-0 level pb-6">
            <div class="tile__icon">
              <figure class="avatar avatar--large" data-text="D"></figure>
            </div>
            <div class="tile__container">
              <p class="tile__title m-0">Dimitri</p>
              <p class="tile__subtitle m-0">Trésorier</p>
            </div>
          </div>
        </div>
      </div>

      <div class="members-type mt-1">
        <h6>Les salariés</h6>
        <div class="row members-type__photos">
          <div class="col-4 tile m-0 level pb-6">
            <div class="tile__icon">
              <figure class="avatar avatar--xlarge">
                <img src="@/assets/images/elisette.png" />
              </figure>
            </div>
            <div class="tile__container">
              <p class="tile__title m-0">Elisette ABADA</p>
              <p class="tile__subtitle m-0">
                Directrice de Femmes Initiatives <br />et formatrice linguistique
              </p>
            </div>
          </div>

          <div class="col-4 tile m-0 level pb-6">
            <div class="tile__icon">
              <figure class="avatar avatar--xlarge">
                <img src="@/assets/images/miriam.png" />
              </figure>
            </div>
            <div class="tile__container">
              <p class="tile__title m-0">Miriam MAMMERI</p>
              <p class="tile__subtitle m-0">
                Chargée de formation linguistique,<br />
                formatrice alphabétisation
              </p>
            </div>
          </div>

          <div class="col-4 tile m-0 level pb-6">
            <div class="tile__icon">
              <figure class="avatar avatar--xlarge"><img src="@/assets/images/samia.png" /></figure>
            </div>
            <div class="tile__container">
              <p class="tile__title m-0">Samia BEN JERAD</p>
              <p class="tile__subtitle m-0">Médiatrice socioculturelle</p>
            </div>
          </div>
        </div>
      </div>

      <div class="members-type mt-1">
        <h6>Les autres membres actifs</h6>
        <div class="row members-type__photos">
          <div class="col-3 tile m-0 level pb-6">
            <div class="tile__icon">
              <figure class="avatar avatar--large" data-text="A"></figure>
            </div>
            <div class="tile__container">
              <p class="tile__title m-0">Annie</p>
              <p class="tile__subtitle m-0">Formatrice FLE</p>
            </div>
          </div>
          <div class="col-3 tile m-0 level pb-6">
            <div class="tile__icon">
              <figure class="avatar avatar--large" data-text="E"></figure>
            </div>
            <div class="tile__container">
              <p class="tile__title m-0">Elisabeth V.</p>
              <p class="tile__subtitle m-0">Formatrice FLE</p>
            </div>
          </div>
          <div class="col-3 tile m-0 level pb-6">
            <div class="tile__icon">
              <figure class="avatar avatar--large" data-text="E"></figure>
            </div>
            <div class="tile__container">
              <p class="tile__title m-0">Eve</p>
              <p class="tile__subtitle m-0">Formatrice FLE</p>
            </div>
          </div>
          <div class="col-3 tile m-0 level pb-6">
            <div class="tile__icon">
              <figure class="avatar avatar--large" data-text="ME"></figure>
            </div>
            <div class="tile__container">
              <p class="tile__title m-0">Mary Ellen</p>
              <p class="tile__subtitle m-0">Formatrice FLE</p>
            </div>
          </div>
        </div>

        <div class="row members-type__photos">
          <div class="col-3 tile m-0 level pb-6">
            <div class="tile__icon">
              <figure class="avatar avatar--large" data-text="A"></figure>
            </div>
            <div class="tile__container">
              <p class="tile__title m-0">Ariane</p>
              <p class="tile__subtitle m-0">Formatrice alphabétisation</p>
            </div>
          </div>
          <div class="col-3 tile m-0 level pb-6">
            <div class="tile__icon">
              <figure class="avatar avatar--large" data-text="C"></figure>
            </div>
            <div class="tile__container">
              <p class="tile__title m-0">Chris</p>
              <p class="tile__subtitle m-0">Formatrice alphabétisation</p>
            </div>
          </div>
        </div>

        <div class="row members-type__photos">
          <div class="col-3 tile m-0 level pb-6">
            <div class="tile__icon">
              <figure class="avatar avatar--large" data-text="S"></figure>
            </div>
            <div class="tile__container">
              <p class="tile__title m-0">Sabine</p>
              <p class="tile__subtitle m-0">Animatrice atelier de conversation</p>
            </div>
          </div>
          <div class="col-3 tile m-0 level pb-6">
            <div class="tile__icon">
              <figure class="avatar avatar--large" data-text="M"></figure>
            </div>
            <div class="tile__container">
              <p class="tile__title m-0">Badria</p>
              <p class="tile__subtitle m-0">Animatrice atelier de conversation</p>
            </div>
          </div>
          <div class="col-3 tile m-0 level pb-6">
            <div class="tile__icon">
              <figure class="avatar avatar--large" data-text="G"></figure>
            </div>
            <div class="tile__container">
              <p class="tile__title m-0">Gisèle</p>
              <p class="tile__subtitle m-0">Formatrice FLE, animatrice atelier de conversation</p>
            </div>
          </div>
        </div>

        <div class="row members-type__photos">
          <div class="col-3 tile m-0 level pb-6">
            <div class="tile__icon">
              <figure class="avatar avatar--large" data-text="N"></figure>
            </div>
            <div class="tile__container">
              <p class="tile__title m-0">Nadège</p>
              <p class="tile__subtitle m-0">Agent d'entretien</p>
            </div>
          </div>
          <div class="col-3 tile m-0 level pb-6">
            <div class="tile__icon">
              <figure class="avatar avatar--large" data-text="K"></figure>
            </div>
            <div class="tile__container">
              <p class="tile__title m-0">Koro</p>
              <p class="tile__subtitle m-0">Aide administrative</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
